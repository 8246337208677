import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Snackbar`}</inlineCode>{` merupakan sebuah pesan pendek berhubungan dengan tindakan yang sudah diambil oleh user, biasanya muncul di bagian bawah window, ada kemungkinan memiliki tombol action.`}</p>
    </PageDescription>
    <h2>{`Android`}</h2>
    <h3>{`Default`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABEUlEQVQ4y+2RXUsCQRSG92d0ExSGYN2alJCBQXeBN/6uQFu/uopodVvrLkLsN4Ufsx/tjvN6jjqtlUrYRTd7mJd3ZjjnmcMZw/VDCO8DXhACSoFDLXxTqKVcrmUGs4wEmAAT4P8CyaWUiEjyl9K56zvcMvwgioF6I9wAvf4bHiwbtvOEjt1F23bIndn5ux67z2h3HLy89ggWQDdmzLuL8D4Y4apUxvHpGfKFC2RP2IvI5c+xd3BIyix5BvvpI+zsplAoXmIwFDPGJ5DpI+HDohcrN3XUGrcw6625k6pm46dqTVxXTNzdWxi7ftyh3rBHE4AWJH3gRMXOd6ukFrnCC1fMkHxMc9xGXP9lhvrwF2nGFOQ86meGwVLoAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/cf323cce801c279a7aa57dd270a5d727/36a81/single.png",
              "srcSet": ["/static/cf323cce801c279a7aa57dd270a5d727/7fc1e/single.png 288w", "/static/cf323cce801c279a7aa57dd270a5d727/36a81/single.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABUElEQVQ4y+2S207CQBCGeRwvjAaMh3hIMGiRFihQDYmJxgsfx4hUQL0zRmN8FKH1StGnUOiBInT7u9tTilGjeMukf/7Zzc6X2c7GTGsAo/eOXn8AOA5YOL7/FE7kLKtlDMaKTYAT4AT4JyB1Qghs26Yivxar+b7DMcOKAlli0MSk3n5+wX1TgaI+oKUwqX6ufqlmS8HjU5vejjJ6HjTmdTfEW1fH3sEhUpyATK6ILaEAIS8hlfbW7l62iKy4jTQvIlfYwWpyE6JURlczXcYI8LWjoby7j+RGBoIogePzrvO5Evh8CWvrHGYSi4jPL2N2bgmJhRVMTcddeEczRoHs7pph4eb2DsfVGmqNC8j1c8i1M8+pqqcNVOS6qxMmuj6qyLi8uoZOa4NZhENhPiQA/WDTF0EcLyfR/JMc/2zw/8KhBFDd7I8lVh92GAX+RwHjA6hM1TG9Ok7RAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4faf9e400a3d466ab344f5ac6c352705/36a81/double.png",
              "srcSet": ["/static/4faf9e400a3d466ab344f5ac6c352705/7fc1e/double.png 288w", "/static/4faf9e400a3d466ab344f5ac6c352705/36a81/double.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`With Action`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABGklEQVQ4y+2RTU/CQBCG+zu8mGA4cCW2AbQkfkRPJl78Y0BtAzeitV96M4bobzIG+iVd9mVnYQFDNBEPXjrZN+9spvN0Mqsl2RRx+ok0nwKcg4Iv/afgG99SLzGIpZXAElgC/xconDGGQoj9Ut9PuGNkebEGqiROcoxe33Dv+vDDR3h+hAc/lPKCSN7JA1GjehA9wfVCPL+MkKQ51GDaYroC7x9jXF3fQG+aaLXPcNgw0TRPpYxWG43jE9SNI+wf1FCp1mDoVej1PVxcnoveWDJWQKKP40z+sdOzcesMYNl96Sq3lt61HCnLdtDp9jC8czERvasJVUJezABxwMQDzvjCVS6FbVF9k7HeofCJ2OMuov4vO1SXv0gx5nL464vqaJ2cAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/85255c4f9e29c746a7cc66dba8fb12fd/36a81/single-action.png",
              "srcSet": ["/static/85255c4f9e29c746a7cc66dba8fb12fd/7fc1e/single-action.png 288w", "/static/85255c4f9e29c746a7cc66dba8fb12fd/36a81/single-action.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABWklEQVQ4y+2RXUvCYBTH/TRdVJTQiMpWiZova1oWBkHRRV8mCMmXyqugC4m+iemmQWF9ijK3OdNt/87jNpsXRdmth/12zgM7P86z49P0HtTOBzrdHmBZYGE5+aewPN+yXuZgLt9EOBFOhH8SUjZNE4ZhEOavYT3fTzhm6F4hK1QqNMrN5xfcVyVIch01iSE72LUkNyDXbWryAyrVBh6fmnQ7cnRsqc+ero+3dwWHxycIbQpIiGnEhG0kkruIizuIbbE6jcB6GNwSj8WVNWwEeQRWOexl9tFqawPHiPC11Ubm4AjBcHwgiMRECCSMxJOICimEogJm5jlMz3GY9S+A5/0ILE9BTIk0jDoqZHdvqzrKt3fI5oooXJSQL159Qecc5fPCJXIEy9l8CadnRVzflKFoOtxdDJfCct8E6IFpOcBzxiiWg0Ev9/8Nl+JKFa07Fqx/OKFX+B9cxydGrdRbX76zogAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/a6d45f4ad2cee7bc008751c3d454fcab/36a81/double-action.png",
              "srcSet": ["/static/a6d45f4ad2cee7bc008751c3d454fcab/7fc1e/double-action.png 288w", "/static/a6d45f4ad2cee7bc008751c3d454fcab/36a81/double-action.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h2>{`IOS`}</h2>
    <h3>{`Default`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA9UlEQVQ4y+2RS07DMBRFs5xIIQzTSZRJFprYHefTVRQxB7EAEKIVIGiT+BdfbLdGRYCEwqCTPOnIz1F8dP0cdIPAvufgQsKW1trxl/L/caGcw7qCWTgLZ+FZhYxLKKUmYcN8Ewo5YmqpUWPXHYVW1jOFl9c3rNoWVVWhrmuH75um+ZXWnHnabMHE6IIFu45BmlHc3N5hsUiQ5znSNEWWZa5PkgRRFCGOY8PFCTEuDWEYYn117ZK+74dDwoErbLbPoHQJQikIoW61+5IQFEWJovyB4/f7h0eTUB8S+hn2TMJOcQr27Oej+Mau9vpTOHV8Ef4H7/gAc67sR8LLpmcAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/fe8a717d600e443ca8092af03876f355/36a81/single.png",
              "srcSet": ["/static/fe8a717d600e443ca8092af03876f355/7fc1e/single.png 288w", "/static/fe8a717d600e443ca8092af03876f355/36a81/single.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABFklEQVQ4y+2Ry0rEQBBF80tZZZNEssgqkPfnTTrxc1RQVypKQhaKoLjRvF/X7hobHRR0xm0KDlXQ3YdbtFK3A6qmRz+MELUsC/GXkvf6YSKHcCmrcBWuwn2EXT9imWfMeyLeiDA/JJxwaA3j/CmUQ910KMsSeZ6jKArqvyHuiTdV3UAGU97qDiLc7V0Ox3Hg+z4RhiF1z/NoDoIAURRRl9i2DcuycH5xiZknFS4S8sS4ur6hQyFxXZd6HMdc6BJHpgnTMGAYOseAruvQNA2qquLk9IxWf63a7cptP+Hp+QVZdgzGUqRpRp2lH/B5s0l2SRIkjPEzhvuHR3Tjsl1Z7t50I8U+BPH226eILtY/hK+OHeF/kI53hKfY+sz0XqYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/fb5bf3c89018475117a1b9f4af14f916/36a81/double.png",
              "srcSet": ["/static/fb5bf3c89018475117a1b9f4af14f916/7fc1e/double.png 288w", "/static/fb5bf3c89018475117a1b9f4af14f916/36a81/double.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`With Action`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABCUlEQVQ4y+2Ry06DQBiFeRwTqQmLWk0QIux8yTLjmosvoYl7jQ+gMbZRoy0wNzjOTKFp40Zx4YZJvpyfAF8OP05ZC6wrDi4kzGnb1vKT0z/HhbIO43JG4Sgchf8qZFxCKTUIU+abUMgGQ49qWqzKTmhkFVN4e//AVVEgTVNkWWbZnQ15nu+RZiYLvCyWqEVjizmrkkHqVdzdP2A2O0Ycx/B9H0EQIIrOEYaBns90hvA8D67r4tCd4GQ6wUV0hNPpAa5vbm3Tz3W9aVhzhcXyFZReglAKQqjN7XVHkhDM54mFkETf3+Tj0zOYbmUb9jusmITZ4m9QXZp3tz+lH0yazx/CrmNP+Bd6xxfJTu1FL2yKWgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/aa0655a4d48a06dbcd86ef50f570d6b7/36a81/single-action.png",
              "srcSet": ["/static/aa0655a4d48a06dbcd86ef50f570d6b7/7fc1e/single-action.png 288w", "/static/aa0655a4d48a06dbcd86ef50f570d6b7/36a81/single-action.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABLklEQVQ4y+2Ry0rDYBCF80puaipBqJVAG21u4Mo3M5f2bRRFXKlYk2ahCIobzf12/OcPoYqCpm4zcJjJn8yXcxIhSnKEcYYsL0BV1zXXX6p9LstLziCW0AN7YA/sAkyzAnVVoeoo2iEzPzgssWnlRbUGtkMUpwiCAJ7n/Srf95lWWN6zvgoQRjFaY8J7lILM3S09KIoCXdehaRoMw+BdVVV+Rp3OTNPEZDLFwXQfx0cyVGWEi8sr0NckFgcyx7i+ucV4vIfZ7JCDaZnDGFTXmxfIsgxJkiCKQ+wMBxjtbkMcbOH07JxHfwuTJnKSlXh+ecV8voDjOHBct5Hjrmcm23ZgWTbvNrt3YrFrNj88PiEt6iZymz1OCzCjnUVRaffbT6FO8TfRZ8YX4H/UMj4AAiLZV+iOSt0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/df245507bf21c3b5afdd68b7eed58d6b/36a81/double-action.png",
              "srcSet": ["/static/df245507bf21c3b5afdd68b7eed58d6b/7fc1e/double-action.png 288w", "/static/df245507bf21c3b5afdd68b7eed58d6b/36a81/double-action.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      